import React from 'react'
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import SvgIcon from '@material-ui/core/SvgIcon'
import styled from 'styled-components'
import iconBrandie from '../../resources/images/svg/icon_brandie.svg'

const SectionSvgIcon = styled(SvgIcon)`
  position: absolute;
  top: -130px;
  left: 0;
  fill: rgb(0 0 0 / 0.04);
  height: 100%;
  width: auto;
  transform: scale(1.5) translateX(60px);
`

const icon = `'data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="110" height="125"><image width="75" height="75" xlink:href="${iconBrandie}" /></svg>'`
const StyledBox = styled(Box)`
  min-height: ${(props) => props.minHeight};

  > div {
    z-index: 1;
  }

  ${({ gradient, textured }) =>
    [
      gradient &&
        `background: transparent linear-gradient(119deg, ${gradient[0]} 0%, ${gradient[1]} 100%) 0% 0% no-repeat padding-box;`,
      textured &&
        `position: relative;
        &::after {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          content: '';
          background-color: rgb(0 0 0 / 0.04);
          mask: url(${icon});
          mask-repeat: repeat;
          mask-size: 230px;
          mask-position: 1rem 1rem, center;
        }`
    ]
      .filter(Boolean)
      .join(' ')}
`

const Section = React.forwardRef(
  (
    {
      children,
      centered,
      svg,
      svgProps,
      textured,
      gradient,
      minHeight,
      ...props
    },
    ref
  ) => {
    return (
      <StyledBox
        ref={ref}
        minHeight={minHeight}
        gradient={gradient}
        textured={textured && 1}
        {...props}
        {...(centered && {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        })}
      >
        {svg && (
          <SectionSvgIcon
            htmlColor='primary.main'
            {...svgProps}
            component={svg}
          />
        )}
        {children}
      </StyledBox>
    )
  }
)

Section.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  bgcolor: PropTypes.string,
  color: PropTypes.string,
  centered: PropTypes.bool,
  gradient: PropTypes.arrayOf(PropTypes.string),
  svg: PropTypes.func,
  svgProps: PropTypes.object,
  textured: PropTypes.bool,
  minHeight: PropTypes.string
}

Section.defaultProps = {
  minHeight: '100vh'
}

export default Section
