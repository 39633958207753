import React, { useState, useRef } from 'react'
import { Box, Grid, Tooltip, Container, useMediaQuery } from '@material-ui/core'
import styled, { keyframes } from 'styled-components'
import { analytics } from '@input-output-hk/front-end-core-libraries'
import { useTheme } from '@material-ui/core/styles'
import SvgInstagram from '../../resources/images/svg/inline/social-instagram.svg'
import SvgFacebook from '../../resources/images/svg/inline/social-facebook.svg'
import SvgQuestion from '../../resources/images/svg/inline/questionmark.svg'

import ImagesQuery from '../queries/ImagesQuery'
import TextField from '../components/TextField'
import Button from '../components/Button'
import Section from '../components/Section'
import BgImage from '../components/BgImage'

const TextFieldContainer = styled.div`
  display: flex;
  align-items: center;

  svg {
    fill: white;
    margin-right: 10px;
    width: 36px;
    height: 36px;
  }
`
const TooltipWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  svg {
    fill: white;
    wdith: 22px;
    height: 22px;
    margin-left: 2px;
    margin-bottom: -4px;
    fill: ${(props) => props.theme.colors.primary.main};
  }
`

const TooltipTitle = styled.div`
  font-size: 1.125rem;
  line-height: 1.6;
  margin: 1rem;
`

const RelativeSection = styled(Section)`
  position: relative;
`
const Shadow = styled.div`
  min-height: 100vh;
  &::after {
    content: '';
    position: absolute;
    opacity: 0.65;
    width: 100%;
    height: 100%;
    z-index: 0;

    top: 0;
    right: 0;
    left: 0;
    bottom: 0;

    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.7) 0%,
      rgba(0, 0, 0, 1) 100%
    );
  }
`

const widthSlide = (props) => keyframes`
  ${
    props.percentage > 10 &&
    `0% { 
      width: 0%;
    }
    100% {
      width: ${props.percentage}%;
    }`
  }
`
const ReachBar = styled.div`
  width: ${(props) =>
    props.percentage > 10 ? `${props.percentage}%` : 'auto'};
  white-space: nowrap;
  display: inline-block;
  height: 36px;
  line-height: 36px;
  padding: 0 15px;
  border-radius: 6px;
  background-color: ${(props) =>
    props.secondary
      ? props.theme.colors.secondary.main
      : props.theme.colors.primary.main};

  animation-name: ${widthSlide};
  animation-duration: 1.4s;
`
const AVG_FB_FOLLOWERS = 338
const AVG_INST_FOLLOWERS = 150

const ReachSection = () => {
  const [ viewReach, setViewReach ] = useState(false)
  const [ instagram, setInstagram ] = useState('')
  const [ facebook, setFacebook ] = useState('')
  const ref = useRef(null)

  const scrollToRef = (ref) =>
    window.scrollTo({ left: 0, top: ref.current.offsetTop, behavior: 'smooth' })

  const createHandler = (stateSetter) => ({ target }) => {
    const value = Math.abs(parseInt(target.value)) || ''
    stateSetter(value)
  }

  const formatPrefix = (n) => {
    const nn = n.toExponential(2).split(/e/)
    const u = Math.floor(+nn[1] / 3)
    return (
      Math.round(nn[0] * Math.pow(10, +nn[1] - u * 3) * 1e2) / 1e2 +
      [ 'p', 'n', 'u', 'm', '', ' k', ' Million', ' Billion', ' Trillion' ][u + 4]
    )
  }

  const calcReach = () => {
    const current = Math.ceil(instagram + facebook) || 0
    const potential =
      Math.ceil(
        instagram * AVG_INST_FOLLOWERS + facebook * AVG_FB_FOLLOWERS + current
      ) || 0

    return {
      current,
      potential,
      currentPercentage: Math.ceil((100 * current) / potential) || 100
    }
  }
  const handleSubmit = (e) => {
    analytics.click({
      category: 'reach_section',
      label: !viewReach ? 'calculate_reach' : 'reset_reach',
      event: e
    })
    e.preventDefault()
    setViewReach(!viewReach)
    scrollToRef(ref)
  }

  const theme = useTheme()
  const smUp = useMediaQuery(theme.breakpoints.up('sm'))

  return (
    <ImagesQuery
      render={(images) => (
        <RelativeSection ref={ref}>
          <BgImage
            title='hero'
            fluid={images.reach.childImageSharp.fluid}
            height='100vh'
          >
            <Shadow>
              <Container maxWidth='md'>
                <Box
                  minHeight='100vh'
                  color='white'
                  display='flex'
                  justifyContent='center'
                  alignItems='center'
                  p={smUp ? 5 : 0}
                >
                  <Grid
                    container
                    spacing={5}
                    justify='center'
                    alignItems='center'
                    height='100%'
                    style={{ zIndex: 1 }}
                  >
                    {!viewReach ? (
                      <React.Fragment>
                        <Grid item xs={12}>
                          <Box textAlign='center'>
                            <h2>Calculate your reach with Brandie</h2>
                            <p>How many followers does your brand page have?</p>
                          </Box>
                        </Grid>

                        <Grid item xs={12}>
                          <form noValidate onSubmit={handleSubmit}>
                            <Grid
                              container
                              spacing={5}
                              justify='center'
                              alignItems='center'
                            >
                              <Grid item xs={12} sm={6}>
                                <TextFieldContainer>
                                  <SvgInstagram />
                                  <TextField
                                    name='instagram'
                                    variant='outlined'
                                    fullWidth
                                    type='number'
                                    id='instagram'
                                    label='# of Instagram Followers'
                                    value={instagram}
                                    onChange={createHandler(setInstagram)}
                                  />
                                </TextFieldContainer>
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <TextFieldContainer>
                                  <SvgFacebook />
                                  <TextField
                                    name='facebook'
                                    variant='outlined'
                                    fullWidth
                                    type='number'
                                    id='facebook'
                                    label='# of Facebook Followers'
                                    value={facebook}
                                    onChange={createHandler(setFacebook)}
                                  />
                                </TextFieldContainer>
                              </Grid>
                              <Grid item xs={12}>
                                <Button type='submit'>
                                  Calculate your reach
                                </Button>
                              </Grid>
                            </Grid>
                          </form>
                        </Grid>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <Grid item xs={10}>
                          <Box textAlign='center'>
                            <h2>Results</h2>
                            <TooltipWrapper>
                              <Tooltip
                                title={
                                  <TooltipTitle>
                                    <p>
                                      <b>Your current maximum social reach:</b>{' '}
                                      We assume that you can reach all your
                                      followers on social media. This is of
                                      course impossible without a paid boost,
                                      but it’s the theoretical maximum.
                                    </p>
                                    <p>
                                      <b>
                                        Your potential social reach with
                                        Brandie:
                                      </b>{' '}
                                      We take the average number of unique
                                      friends and followers users have on
                                      Facebook (Avg. {AVG_FB_FOLLOWERS} friends)
                                      and Instagram (Avg. {AVG_INST_FOLLOWERS}{' '}
                                      followers), this to give a fair estimate,
                                      then we multiply that by your total number
                                      of existing followers on each platform .
                                      This is how many new people you could
                                      reach if you utilized your current
                                      customer’s social media networks, with the
                                      help of Brandie.
                                    </p>
                                  </TooltipTitle>
                                }
                              >
                                <p>
                                  How we calculated <SvgQuestion />
                                </p>
                              </Tooltip>
                            </TooltipWrapper>
                          </Box>
                        </Grid>
                        <Grid item xs={10}>
                          <p>Your current maximum social reach</p>
                          <ReachBar
                            secondary
                            percentage={calcReach().currentPercentage}
                          >
                            {formatPrefix(calcReach().current)}
                          </ReachBar>
                        </Grid>
                        <Grid item xs={10}>
                          <p>Your potential social reach with Brandie</p>
                          <ReachBar percentage={100}>
                            {formatPrefix(calcReach().potential)}
                          </ReachBar>
                        </Grid>
                        <Grid item xs={10}>
                          <Button onClick={handleSubmit}>Reset</Button>
                        </Grid>
                      </React.Fragment>
                    )}
                  </Grid>
                </Box>
              </Container>
            </Shadow>
          </BgImage>
        </RelativeSection>
      )}
    />
  )
}

ReachSection.propTypes = {}

export default ReachSection
