import React from 'react'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import styled from 'styled-components'
import { Fade } from './Transitions'
import Visible from './Visible'

import IconBrandieSvg from '../../resources/images/svg/inline/icon_brandie.svg'
import ContactForm from './ContactForm'
import Section from './Section'

const GridContainer = styled(Grid)`
  min-height: 100vh;
  padding: 10rem 0;
  > div {
    z-index: 1;
  }

  p {
    margin-bottom: 22px;
  }
`

const ContactSection = React.forwardRef((props, ref) => {
  return (
    <Section
      ref={ref}
      position='relative'
      overflow='hidden'
      color='secondary.contrastText'
      gradient={[ 'rgba(111, 111, 196, 1)', 'rgba(98, 98, 186, 1)' ]}
      svg={IconBrandieSvg}
      svgProps={{ viewBox: '0 0 152.34 259.7' }}
    >
      <GridContainer container spacing={2} justify='center' alignItems='center'>
        <Grid item xs={12} sm={5}>
          <Box mx={3}>
            <Visible
              render={(isVisible, ref) => (
                <Fade in={isVisible}>
                  <h2 ref={ref}>Want to know more?</h2>
                </Fade>
              )}
            />
            <Visible
              render={(isVisible, ref) => (
                <React.Fragment>
                  <Fade in={isVisible}>
                    <p ref={ref}>
                      92% of consumers online trust content from friends and
                      family above all other forms of brand messages.
                    </p>
                  </Fade>
                  <Fade in={isVisible}>
                    <p>
                      Get in touch and start harnessing the power of digital
                      Word of Mouth marketing.
                    </p>
                  </Fade>
                  <Fade in={isVisible}>
                    <p>
                      Get in touch through the form or contact us at <br />
                      <a href='mailto:info@brandie.in' target='_blank' rel='noreferrer'>
                        info@brandie.in
                      </a>
                    </p>
                  </Fade>
                </React.Fragment>
              )}
            />
          </Box>
        </Grid>

        <Grid item xs={12} sm={5}>
          <Box m={3}>
            <ContactForm />
          </Box>
        </Grid>
      </GridContainer>
    </Section>
  )
})

ContactSection.propTypes = {}
export default ContactSection
