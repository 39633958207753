import React, { useRef } from 'react'
import styled from 'styled-components'
import { Grid, Box, useMediaQuery } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import { analytics } from '@input-output-hk/front-end-core-libraries'

import Layout from '../components/Layout'
import Hero from '../components/HeroBrands'
import Section from '../components/Section'
import Visible from '../components/Visible'
import { Fade } from '../components/Transitions'
import OptImages from '../components/OptImages'
import ReachSection from '../components/ReachSection'
import ContactSection from '../components/ContactSection'

import IconBrandieSvg from '../../resources/images/svg/inline/icon_brandie.svg'
import BrandsPageQuery from '../queries/BrandsPageQuery'

const ResponsiveTitle = styled.div`
  h1 {
    margin: 0;
    font-size: 90px;

    @media (min-width: 600px) {
      font-size: 145px;
    }
  }
`

export default () => {
  const theme = useTheme()
  const smUp = useMediaQuery(theme.breakpoints.up('sm'))
  const xlUp = useMediaQuery(theme.breakpoints.up('xl'))
  const contactSectionRef = useRef(null)
  const scrollToRef = (ref) =>
    window.scrollTo({ left: 0, top: ref.current.offsetTop, behavior: 'smooth' })

  return (
    <BrandsPageQuery
      render={(content) => (
        <Layout>
          <Hero
            ecenter1={<h2>Harness the power of word of mouth at scale!</h2>}
            link='Get in touch!'
            onBtnClickHandler={(e) => {
              analytics.click({
                category: 'hero',
                label: 'cta',
                event: e
              })
              scrollToRef(contactSectionRef)
            }}
          />
          <Section
            position='relative'
            color='white'
            gradient={[ '#5AB7B1', '#4CAAA4' ]}
            centered
            svg={IconBrandieSvg}
            svgProps={{ viewBox: '0 0 152.34 259.7' }}
            textAlign='center'
          >
            <Box px={smUp ? 10 : 2} py={smUp ? 10 : 8}>
              <Grid
                container
                spacing={5}
                justify='center'
                alignItems='center'
                direction='column'
              >
                <Grid item xs={12} sm={6}>
                  <div
                    style={{
                      height: '140px',
                      width: '140px',
                      background: 'white',
                      borderRadius: '100px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    <OptImages.EmojiPeace style={{ width: '70px' }} />
                  </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Visible
                    render={(isVisible, ref) => (
                      <Fade in={isVisible}>
                        <h2 ref={ref}>Why Brandie?</h2>
                      </Fade>
                    )}
                  />
                </Grid>
                <Grid container item xs={12} sm={8} spacing={8}>
                  <Visible
                    render={(isVisible, ref) => (
                      <React.Fragment>
                        <Fade in={isVisible}>
                          <p ref={ref}>
                            92% of consumers online trust content from friends
                            and family above all other forms of brand messages.
                            With Brandie, you can drive positive recommendations
                            through your consumer base, creating top-of-mind
                            conversations that showcase your brand as
                            trustworthy and reliable.
                          </p>
                        </Fade>
                        <Fade in={isVisible}>
                          <p>
                            Brandie provides you with the tools you need to
                            effectively activate your customers to become
                            advocates and ambassadors while removing the burdens
                            of extra work associated with large communities and
                            structured UGC strategies.
                          </p>
                        </Fade>
                      </React.Fragment>
                    )}
                  />
                </Grid>
              </Grid>
            </Box>
          </Section>

          <ReachSection />

          <Section centered bgcolor='#ffffff'>
            <Grid
              container
              spacing={0}
              justify='center'
              alignItems='center'
              direction='row'
            >
              <Grid item xs={12} sm={6}>
                <Box color='text.primary' m={xlUp ? 15 : smUp ? 10 : 5}>
                  <Visible
                    render={(isVisible, ref) => (
                      <Fade in={isVisible}>
                        <h2 ref={ref}>Get insights</h2>
                      </Fade>
                    )}
                  />
                  <Visible
                    render={(isVisible, ref) => (
                      <Fade in={isVisible}>
                        <p ref={ref}>
                          Get ownership of your customer data. With Brandie, you
                          get full insights into what your customers are saying
                          about you on social media, for both public and private
                          accounts. You will get access to authentic
                          user-generated content for your marketing activities
                          and data to help you improve your sales strategies.
                        </p>
                      </Fade>
                    )}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box mb={0} mt={0} mr={smUp ? -4 : 0}>
                  <OptImages.MacbookInsights
                    style={{ height: '100%', transform: 'scale(1.15)' }}
                  />
                </Box>
              </Grid>
            </Grid>
          </Section>

          <Section centered textured gradient={[ '#6F6FC4', '#6262BA' ]}>
            <Grid
              container
              spacing={0}
              justify='center'
              alignItems='center'
              direction='row-reverse'
            >
              <Grid item xs={12} sm={6}>
                <Box color='white' m={xlUp ? 15 : smUp ? 10 : 5}>
                  <Visible
                    render={(isVisible, ref) => (
                      <Fade in={isVisible}>
                        <h2 ref={ref}>Create meaningful campaigns</h2>
                      </Fade>
                    )}
                  />
                  <Visible
                    render={(isVisible, ref) => (
                      <Fade in={isVisible}>
                        <p ref={ref}>
                          Get started with your customized campaigns, contests,
                          and surveys and instantly gratify your community at
                          scale. Mobilize your customers to a marketing force of
                          authentic voices on social media.
                        </p>
                      </Fade>
                    )}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box mb={0} mt={0}>
                  <OptImages.IphoneReward
                    style={{ height: '100%', transform: 'scale(1.05)' }}
                  />
                </Box>
              </Grid>
            </Grid>
          </Section>

          <Section centered bgcolor='#ffffff' my={2}>
            <Grid
              container
              spacing={0}
              justify='center'
              alignItems='center'
              direction='row'
            >
              <Grid item xs={12} sm={6}>
                <Box color='text.primary' m={xlUp ? 15 : smUp ? 10 : 5}>
                  <Visible
                    render={(isVisible, ref) => (
                      <Fade in={isVisible}>
                        <h2 ref={ref}>Build your community</h2>
                      </Fade>
                    )}
                  />
                  <Visible
                    render={(isVisible, ref) => (
                      <React.Fragment>
                        <Fade in={isVisible}>
                          <p ref={ref}>
                            Gamified and built for scale. With Brandie, you can
                            reward positive posts and train your community to
                            spread more positive stories about your brand,
                            without extra effort from your side.
                          </p>
                        </Fade>
                        <Fade in={isVisible}>
                          <p>
                            Reward the behavior you want to see, and you will
                            see the positive spiral kick-off.
                          </p>
                        </Fade>
                      </React.Fragment>
                    )}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box mb={0} mt={0} mr={smUp ? -4 : 0}>
                  <OptImages.MacbookAudience
                    style={{ height: '100%', transform: 'scale(1.15)' }}
                  />
                </Box>
              </Grid>
            </Grid>
          </Section>

          <Section centered textured gradient={[ '#E0B148', '#DDA21B' ]}>
            <Grid
              container
              spacing={0}
              justify='center'
              alignItems='center'
              direction='row-reverse'
            >
              <Grid item xs={12} sm={6}>
                <Box color='white' m={xlUp ? 15 : smUp ? 10 : 5}>
                  <Visible
                    render={(isVisible, ref) => (
                      <Fade in={isVisible}>
                        <h2 ref={ref}>Scale</h2>
                      </Fade>
                    )}
                  />
                  <Visible
                    render={(isVisible, ref) => (
                      <Fade in={isVisible}>
                        <p ref={ref}>
                          Brandie’s AI-powered tool enables you to work with
                          thousands of advocates with the same effort it takes
                          you to work with one influencer today.
                        </p>
                      </Fade>
                    )}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box mb={0} mt={0} ml={smUp ? -4 : 0}>
                  <OptImages.MacbookScale
                    style={{ height: '100%', transform: 'scale(1.15)' }}
                  />
                </Box>
              </Grid>
            </Grid>
          </Section>

          <Section
            position='relative'
            overflow='hidden'
            color='white'
            gradient={[ '#5AB7B1', '#4CAAA4' ]}
            centered
            svg={IconBrandieSvg}
            svgProps={{ viewBox: '0 0 152.34 259.7' }}
            textAlign='center'
            py={10}
          >
            <Grid
              container
              spacing={5}
              justify='center'
              alignItems='center'
              direction='column'
            >
              <Grid item xs={12} sm={6}>
                <Visible
                  render={(isVisible, ref) => (
                    <Fade in={isVisible}>
                      <h2 ref={ref}>How it works</h2>
                    </Fade>
                  )}
                />
              </Grid>
              <Grid container item xs={12} sm={10} spacing={6}>
                <Visible
                  render={(isVisible, ref) => (
                    <Fade in={isVisible} timeout={0}>
                      <Grid item xs={12} sm={4}>
                        <ResponsiveTitle>
                          <h1 ref={ref}>1</h1>
                        </ResponsiveTitle>
                        <p style={{ fontSize: '2.3rem', marginTop: '0' }}>
                          Provide rewards
                        </p>
                      </Grid>
                    </Fade>
                  )}
                />
                <Visible
                  render={(isVisible, ref) => (
                    <Fade in={isVisible} timeout={700}>
                      <Grid item xs={12} sm={4}>
                        <ResponsiveTitle>
                          <h1 ref={ref}>2</h1>
                        </ResponsiveTitle>
                        <p style={{ fontSize: '2.3rem', marginTop: '0' }}>
                          Activate customers
                        </p>
                      </Grid>
                    </Fade>
                  )}
                />
                <Visible
                  render={(isVisible, ref) => (
                    <Fade in={isVisible} timeout={1400}>
                      <Grid item xs={12} sm={4}>
                        <ResponsiveTitle>
                          <h1 ref={ref}>3</h1>
                        </ResponsiveTitle>
                        <p style={{ fontSize: '2.3rem', marginTop: '0' }}>
                          Go viral
                        </p>
                      </Grid>
                    </Fade>
                  )}
                />
              </Grid>
            </Grid>
          </Section>

          <ContactSection ref={contactSectionRef} />
        </Layout>
      )}
    />
  )
}
