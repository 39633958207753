import styled from 'styled-components'
import _TextField from '@material-ui/core/TextField'

const TextField = styled(_TextField)`
  input {
    background: white;
  }

  .MuiOutlinedInput-multiline {
    background: white;

    textarea {
      min-height: 60px;
    }
  }

  .MuiInputLabel-outlined {
    color: #898e89;
    height: 20px;
  }

  .MuiInputLabel-filled {
    color: black;
  }

  .MuiInputLabel-shrink {
    background: #66bf7e;
    color: white;
    padding: 5px 10px;
    border-radius: 8px;
    margin-left: -8px;
    height: auto;
  }
`

TextField.propTypes = {}

TextField.defaultProps = {}

export default TextField
