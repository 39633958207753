import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import styled, { keyframes, css } from 'styled-components'

const Container = styled.div`
  position: relative;
  background-color: ${({ bc }) => bc};
  min-height: ${({ height }) => height};
`

const fade = keyframes`
  0% { 
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`
const ANIMATE_DURATION = 2000
const FakeBgImage = styled(Img)`
  position: absolute !important;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  min-height: ${({ height }) => height};
  z-index: -2;

  ${(props) =>
    props.variant === 'top' &&
    css`
      z-index: -1;
    `}

  &.animated {
    animation-name: ${fade};
    animation-duration: ${ANIMATE_DURATION}ms;
  }

  & > img {
    object-fit: cover !important;
    object-position: 0% 0% !important;
    font-family: 'object-fit: cover !important; object-position: 0% 0% !important;';
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    min-height: ${({ mobileHeight }) => mobileHeight};
  }
`
const Content = styled.div`
  min-height: ${(props) => props.height};
  width: 100%;
`
const BgImage = ({
  fluid,
  title,
  height,
  mobileHeight,
  overlayColor,
  children,
  className,
  crossfade
}) => {
  const [ topFluid, setTopFluid ] = useState(null)
  const [ bottomFluid, setBottomFluid ] = useState(fluid)
  const [ animate, setAnimate ] = useState(false)
  useEffect(() => {
    let timeout
    if (crossfade) {
      if (fluid.src !== bottomFluid.src) {
        setTopFluid(fluid)
        setAnimate(true)
        timeout = setTimeout(() => {
          setBottomFluid(fluid)
          setAnimate(false)
        }, ANIMATE_DURATION)
      }
    } else {
      setBottomFluid(fluid)
    }
    return () => clearTimeout(timeout)
  }, [ fluid ])

  return (
    <Container className={className} bc={overlayColor} height={height}>
      <Content height={height}>{children}</Content>
      {topFluid && (
        <FakeBgImage
          fluid={topFluid}
          title={title}
          height={height}
          mobileHeight={mobileHeight}
          variant='top'
          className={animate ? 'animated' : ''}
        />
      )}
      <FakeBgImage
        fluid={bottomFluid}
        title={title}
        height={height}
        mobileHeight={mobileHeight}
        variant='bottom'
      />
    </Container>
  )
}
BgImage.propTypes = {
  fluid: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  height: PropTypes.string,
  mobileHeight: PropTypes.string,
  overlayColor: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  crossfade: PropTypes.bool
}
BgImage.defaultProps = {
  height: null,
  mobileHeight: null,
  overlayColor: 'transparent',
  children: null,
  className: null,
  crossfade: false
}
export default BgImage
